/**
 * External dependencies
 */
import React, { useState } from 'react';
import classNames from 'classnames';
import convert from 'convert-units';

/**
* Internal dependencies
*/
import { Flex, FlexCol } from 'components/flex/flex';

type Props = {
  onChange: Function,
  error: boolean,
  value: any,
}

const Height = ({ onChange, error, value }: Props) => {
  const [unit, setUnit] = useState('in');
  const [focused, setFocused] = useState(false);
  
  const feet = Math.floor(convert(value).from('cm').to('ft'))
  const inches = Math.floor(convert(value).from('cm').to('in') % 12);

  const handleUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => setUnit(event.target.value)
  const handleImperialChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let centimeter, ft_to_cm, in_to_cm;
    if (event.target.getAttribute('name') === 'inches') {
      ft_to_cm = convert(feet).from('ft-us').to('cm');
      in_to_cm = convert(parseInt(event.target.value)).from('in').to('cm');
    } else {
      ft_to_cm = convert(parseInt(event.target.value)).from('ft-us').to('cm');
      in_to_cm = convert(inches).from('in').to('cm');
    }
    centimeter = ft_to_cm + in_to_cm;
    onChange(centimeter ? centimeter : 0);
  };
  
  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFocused(true);
    event.target.select();
  };

  const handeBlur = () => setFocused(false);

  const unitOutput = () => {
    switch (unit) {
      case 'in':
        return (
          <div className={classNames('height__unit imperial-holder', {'is-focused': focused, 'has-error': error})}>
            <Flex>
              <FlexCol>
                <Flex className="align-center">
                  <FlexCol>
                    <input
                      type="text"
                      id="foot"
                      name="foot"
                      value={value ? feet : ''}
                      onChange={handleImperialChange} 
                      onFocus={handleFocus}
                      onBlur={handeBlur}
                    />
                  </FlexCol>
                  <FlexCol>
                    <label htmlFor="foot">ft</label>
                  </FlexCol>
                </Flex>
              </FlexCol>
              <FlexCol>
                <Flex className="align-center">
                  <FlexCol>
                    <input
                      type="text"
                      id="inches"
                      name="inches"
                      value={value ? inches : ''}
                      onChange={handleImperialChange} 
                      onFocus={handleFocus}
                      onBlur={handeBlur}
                    />
                  </FlexCol>
                  <FlexCol>
                    <label htmlFor="inches">in</label>
                  </FlexCol>
                </Flex>
              </FlexCol>
            </Flex>
          </div>
        )
      case 'cm':
        return (
          <div className={classNames('height__unit metrics-holder', {'is-focused': focused, 'has-error': error})}>
            <Flex className="align-center">
              <FlexCol>
                <input
                  type="text"
                  id="centimeters"
                  name="centimeters"
                  value={value ? value : ''}
                  onChange={(event) => onChange(parseInt(event.target.value))} 
                  onFocus={handleFocus}
                  onBlur={handeBlur}
                />
              </FlexCol>
              <FlexCol>
                <label htmlFor="centimeters">cm</label>
              </FlexCol>
            </Flex>
          </div>
        )
    }
  }

  return (
    <div className="height">
      <Flex className="between">
        <FlexCol>
          {unitOutput()}          
        </FlexCol>
        <FlexCol>
          <div className="radios">
            <div className="radio">
              <input type="radio" name="unit" value="in" checked={unit === 'in'} onChange={handleUnitChange} />
              <label>in</label>
            </div>
            <div className="radio">
              <input type="radio" name="unit" value="cm" checked={unit === 'cm'} onChange={handleUnitChange} />
              <label>cm</label>
            </div>
          </div>
        </FlexCol>
      </Flex>
    </div>
  )
}

export default Height
