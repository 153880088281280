/**
 * External dependencies
 */
import React from 'react';
import {
  NavLink,
  useHistory 
} from "react-router-dom";
import classNames from 'classnames';

/**
 * Internal dependencies
 */
import Logo from 'components/logo/logo';

const Header = ({ alt }: {alt?: boolean}) => {
  let history = useHistory();

  return (
    <header className={classNames('header', {'is-alt': alt})}>
      <div className="container">
        <NavLink to="/" className="header__logo">
          <Logo color={history.location.pathname === '/' ? '#000' : '#fff'} />
        </NavLink>
        {history.location.pathname === '/home' && 
          <ul className="header__nav">
            <li>
              <NavLink to="/">About</NavLink>
            </li>
            <li>
              <NavLink to="/">Team</NavLink>
            </li>
            <li>
              <NavLink to="/">Contact us</NavLink>
            </li>
          </ul>
        }
        
      </div>
    </header>
  )
}

export default Header
