/**
 * External dependencies
 */
import React, { useState } from 'react';
import classNames from 'classnames'

/**
 * Internal dependencies
 */
import { Flex, FlexCol } from 'components/flex/flex';
import { IcoCheckGreen } from 'components/icon/icon'

import Img1 from 'assets/images/img1.png';
import Img2 from 'assets/images/img2.png';
import Img3 from 'assets/images/img3.png';

const Extension = () => {
  const [ind, setInd] = useState(0);
  
  return (
    <div className="extension-page bg-dark">
      <div className="main-wrapper">
        <div className="container">
          <h3>HOW IT WORKS</h3>
          <Flex className="extension-row">
            <FlexCol className="extension-col extension-col--left">
              <ol className="ext-list">
                <li className={classNames('visible', {'is-checked': ind > 0})}>
                  <h5>Add to Chrome <img src={IcoCheckGreen} alt="" /></h5>
                  <div className="ext-list__content">
                    <a 
                      href="https://chrome.google.com/webstore/"
                      rel="noreferrer"
                      target="_blank"
                      className="btn"
                      onClick={() => setInd(1)}
                    >
                      ADD TO CHROME
                    </a>
                  </div>
                </li>
                <li className={classNames({'is-checked ': ind > 1, 'visible': ind >= 1})}>
                  <h5>Go to a product page on a retailer’s site <img src={IcoCheckGreen} alt="" /></h5>
                  <div className="ext-list__content">
                    <a 
                      href="https://chrome.google.com/webstore/"
                      rel="noreferrer"
                      target="_blank"
                      className="btn"
                      onClick={() => setInd(2)}
                    >
                      next step
                    </a>
                  </div>
                </li>
                <li className={classNames({'is-checked': ind > 2, 'visible': ind >= 2})}>
                  <h5>Click on the Klothed icon that slides out <img src={IcoCheckGreen} alt="" /></h5>
                  <div className="ext-list__content">
                    <a
                      href="https://chrome.google.com/webstore/"
                      rel="noreferrer"
                      target="_blank"
                      className="btn"
                      onClick={() => setInd(3)}
                    >
                      next step
                    </a>
                  </div>
                </li>
                <li className={classNames({'is-checked': ind > 3, 'visible': ind >= 3})}>
                  <h5>There you are, wearing the product <img src={IcoCheckGreen} alt="" /></h5>
                  <div className="ext-list__content">
                    <h2>We bet it looks better on <i>you</i>.</h2>
                    <a href="/" className="gradient-btn ">TRY IT ON</a>
                  </div>
                </li>
              </ol>
            </FlexCol>
            <FlexCol className="extension-col extension-col--right">
              {ind <= 1 && <img src={Img1} alt="" />}
              {ind === 2 && <img src={Img2} alt="" />}
              {ind === 3 && <img src={Img3} alt="" />}
            </FlexCol>
          </Flex>
          
        </div>
      </div>
    </div>
  )
}

export default Extension
