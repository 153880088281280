import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

/**
 * Internal dependencies
 */
import SignUp from "views/sign-up";
import Steps from "views/steps";
import Home from "views/home";
import Extension from "views/extension";
import Defaults from "views/defaults";
import Header  from 'components/header/header';
import Footer from "components/footer/footer";

const Routes = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  const checkAuthenticated = (element : JSX.Element) => {
    if (isLoading) {
      return null;
    } else {
      if (isAuthenticated) {
        return element
      } else {
        return <SignUp />
      }
    }
  };

  return (
    <Router basename={'/'}>
      <Switch>
        <Route path="/" exact>
          <Header />
          <Home />
          <Footer />
        </Route>
        <Route path="/steps" exact>
          {checkAuthenticated(
            <>
              <Header alt />
              <Steps />
            </>
          )}
        </Route>
        <Route path="/defaults" exact>
          {checkAuthenticated(
            <>
              <Header alt />
              <Defaults />
            </>
          )}
        </Route>
        <Route path="/extension" exact>
          {checkAuthenticated(
            <>
              <Header alt />
              <Extension />
            </>
          )}
        </Route>
        <Route path="/sign-up" exact>
          <SignUp />
        </Route>
      </Switch>
    </Router>

  )
}

export default Routes
