import React from 'react';
import classNames from 'classnames'

type Props = {
  className?: string,
  children: React.ReactNode,
  half?: boolean
}

const Flex = ({ children, className }: Props) => {
  return (
    <div className={classNames(['flex', className])}>
      {children}
    </div>
  )
}

const FlexCol = ({ children, className, half }: Props) => {
  return (
    <div className={classNames(['flex__col', className, {'flex__col--half': half}])}>
      {children}
    </div>
  )
}

export {
  Flex,
  FlexCol
}
