/**
 * External dependencies
 */
import React, { Fragment, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { has, isEmpty } from 'ramda';
import classNames from 'classnames';

/**
 * Internal dependencies
 */
import Height from 'blocks/height/height';
import { Flex, FlexCol } from 'components/flex/flex';
import StepHolder from 'components/step-holder/step-holder';
import DragDrop from 'components/drag-drop/drag-drop';
import TipRow from 'components/tip-row/tip-row';
import { IcoCamera, IcoStand, IcoSolo, IcoNoDark } from 'components/icon/icon';

import ok1 from 'assets/images/ok1.png';
import ok2 from 'assets/images/ok2.png';
import ok3 from 'assets/images/ok3.png';
import ok4 from 'assets/images/ok4.png';

import not_ok1 from 'assets/images/not-ok1.png';
import not_ok2 from 'assets/images/not-ok2.png';
import not_ok3 from 'assets/images/not-ok3.png';
import not_ok4 from 'assets/images/not-ok4.png';

const schema = yup.object().shape({
  height: yup
    .number()
    .moreThan(60.96, 'Your height is too small')
    .lessThan(274.32, 'Your height is too large')
    .required('Please enter a valid height value'),
  file: yup
    .string()
    .required(),
});

type FormData = {
  height: number,
  file: string,
};

const Steps = () => {
  const [step, setStep] = useState(1);
  const [showErrors, setShowErrors] = useState(false);
  const { control, handleSubmit, formState: { errors, isValid }, getValues } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: "onChange"
  });
  const onSubmit = (data: FormData) => console.log('submitted');
  const handleStepChange = (ind: number) => {
    setShowErrors(hasMessage(errors.height));
    if(!hasMessage(errors.height)) {
      setStep(ind);
    }
  };

  const values = getValues();
  const hasMessage = has('message');

  const outputErrors = Object.entries(errors).map(([key, obj]) => (
    <div className="error" key={key}>
      {obj?.message}
    </div>
  ));

  return (
    <div className="steps bg-dark">
      <div className="main-wrapper">
        <div className="container">
          <Flex className="between mobile-block">
            <FlexCol className="steps-left">
              <h1>Try on styles from<br/> your favorite online<br/> retailer.</h1>
              {step === 2 && (
                <Fragment>
                  <TipRow icon={IcoCamera} img1={ok1} img2={not_ok1}>
                    <h4>FACE THE CAMERA</h4>
                    <h5>make sure we can see your arms and legs</h5>
                  </TipRow>
                  <TipRow icon={IcoStand} img1={ok2} img2={not_ok2}>
                    <h4>STAND UPRIGHT AND SHOW YOUR FULL BODY</h4>
                    <h5>no selfies</h5>
                  </TipRow>
                  <TipRow icon={IcoSolo} img1={ok3} img2={not_ok3}>
                    <h4>GO SOLO</h4>
                    <h5>be in the foreground - we want to focus on you</h5>
                  </TipRow>
                  <TipRow icon={IcoNoDark} img1={ok4} img2={not_ok4}>
                    <h4>DON’T WEAR DARK CLOTHES</h4>
                    <h5>light-colored clothes work best</h5>
                  </TipRow>
                </Fragment>
              )}
            </FlexCol>
            <FlexCol className="steps-right">
              {!isEmpty(errors) && showErrors &&
                <div className="errors">
                  {outputErrors}
                </div>
              }
              <form onSubmit={handleSubmit(onSubmit)} className="step-form">
                <StepHolder title="ENTER YOUR HEIGHT" subtitle="Create your profile" className={classNames({'is-visible': step === 1})}>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Fragment>
                        <Height 
                          onChange={onChange}
                          error={showErrors}
                          value={value} 
                        />
                        <button 
                          type="button"
                          disabled={!value}
                          className="next-step btn"
                          onClick={() => handleStepChange(2)}
                        >NEXT STEP</button>
                      </Fragment>
                    )}
                    name="height"
                    defaultValue=""
                  />
                </StepHolder>
                <StepHolder title="UPLOAD A PHOTO" subtitle="Create your profile"  className={classNames({'is-visible': step === 2})}>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DragDrop onChange={onChange} value={value} />
                    )}
                    name="file"
                    defaultValue=""
                  />
                  {isValid && <button type="button" className="next-step btn">ALL SET</button>}
                  <button 
                    type="button"
                    disabled={values.height?.toString() === ''} 
                    className="prev-step btn"
                    onClick={() => handleStepChange(1)}
                  >PREV STEP</button>
                </StepHolder>
              </form>
            </FlexCol>
          </Flex>
        </div>
      </div>
    </div>
  )
}

export default Steps
