/*
* External dependencies
*/
import React from 'react'
import { useAuth0 } from "@auth0/auth0-react";
 
/*
* Internal dependencies
*/

const AuthButton = () => {
    const { loginWithRedirect, logout, isAuthenticated, isLoading } = useAuth0();
    const onClick = () => {
        isAuthenticated ? logout() : loginWithRedirect();
    }
    return (
        <div onClick={onClick} className={`gradient-btn ${isLoading ? "gradient-btn-hidden" : ""}`}>
            {isAuthenticated ? "SIGN OUT" : "SIGN UP"}
        </div>
    );
}
 
export default AuthButton
 