import React from 'react';
import classNames from 'classnames'

type Props = {
  children: React.ReactNode,
  title?: string,
  subtitle?: string,
  className?: string
}

const StepHolder = ({ children, title, subtitle, className }: Props) => {
  return (
    <div className={classNames('step-holder', [className])}>
      <div className="step-holder__head">
        {subtitle &&  <h5>{subtitle}</h5>}
        {title && <h4>{title}</h4>}
      </div>
      <div className="step-holder__body">
        {children}
      </div>
    </div>
  )
}

export default StepHolder
