/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */

type Prop = {
  children: React.ReactNode,
  style?: object
}

const TextImage = ({ children, style }: Prop) => {
  return (
    <div className="text-image" style={style}>
      <div className="container">
        {children}
      </div>
    </div>
  )
}

export default TextImage
