import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import { useDropzone} from 'react-dropzone';
import axios from 'axios';

type Prop = {
  onChange: Function,
  value: String,
  error?: boolean
}

const DragDrop = ({ onChange, error }: Prop) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState('');
  const [hasError, setError] = useState(false);
  const [width, setWidth] = useState(0);

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
    
    onDrop: (acceptedFiles: any) => {
      const url: any = process.env.REACT_APP_UPLOAD_URL;
      setWidth(0);
      setLoading(true);
      axios.post(url, {
        data: acceptedFiles[0]
      }, {
        onUploadProgress: function(progressEvent) {
          const percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          setWidth(percentCompleted);
        }
      }).then(res => {
        setFile(URL.createObjectURL(acceptedFiles[0]));
        setTimeout(function() {
          setLoading(false);
          onChange(URL.createObjectURL(acceptedFiles[0]))
        }, 300)
      }).catch(res => {
        setError(true);
      })
    }
  });

  const handleCancel = (event: any) => {
    event.preventDefault();
    return setFile('');
  };

  return (
    <div className="drag-drop">
      {(error || hasError) && <div className="drag-drop__error">
        <p>Please try with another photo.<br/>Check out these tips and try again.</p>
        <svg width="36" height="23" viewBox="0 0 36 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9271 17.6095V23L-4.76837e-07 14.4478L11.7082 5.58455L11.8723 9.57559C19.0395 10.6122 29.8723 7.08767 36 0.401385C32.9362 10.1457 27.465 17.9205 11.9271 17.6095Z" fill="white"/>
        </svg>
        <a href="/" className="btn">TRY AGAIN</a>
      </div>}
      
      {
        loading
        ? (
          <Fragment>
            <div className="loading-outer">
              <div className="loading">
                <div className="loading__inner">
                  <span style={{width: `${width}%`}}></span>
                </div>
              </div>
              <p>uploading ...</p>
            </div>
          </Fragment>
        )
        : (
          <div 
            {...getRootProps({
                className: classNames('dropzone', {'has-preview': file}),
                style: {backgroundImage: `url(${file})`}
              })
            }
          >
            <input {...getInputProps()} />
            {!file && <p>Drag a photo here <br/>or <span>browse</span></p>}
          </div>
        )
      }
      {file && <a href="/" className="cancel-btn" onClick={handleCancel}>Cancel</a>}
    </div>
  );
}

export default DragDrop;