/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import { Flex, FlexCol } from 'components/flex/flex';
import { IcoCheckMark, IcoClose } from 'components/icon/icon';

type Props = {
  children: React.ReactNode,
  icon: string,
  img1: string,
  img2: string,
}

const TipRow = ({ children, icon, img1, img2 }: Props) => {
  return (
    <div className="tip-row">
      <Flex className="between">
        <FlexCol className="tip-row__icon">
          <img src={icon} alt="" />
        </FlexCol>
        <FlexCol className="tip-row__content">
          <Flex className="between">
            <FlexCol>
              {children}
            </FlexCol>
            <FlexCol>
              <Flex>
                <FlexCol className="tip-row__image-holder">
                  <div className="tip-row__img" style={{backgroundImage: `url(${img1})`}}>
                    <div className="tip-row__img-icon ok">
                      <img src={IcoCheckMark} alt="" />
                    </div>
                  </div>
                </FlexCol>
                <FlexCol className="tip-row__image-holder">
                  <div className="tip-row__img" style={{backgroundImage: `url(${img2})`}}>
                    <div className="tip-row__img-icon not-ok">
                      <img src={IcoClose} alt="" />
                    </div>
                  </div>
                </FlexCol>
              </Flex>
            </FlexCol>
          </Flex>
        </FlexCol>
      </Flex>
    </div>
  )
}

export default TipRow;
