import React from 'react';
import Routes from 'routes';
import ReactDOM from 'react-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Auth0Provider } from "@auth0/auth0-react";

/**
 * Internal dependencies
 */
import 'assets/scss/main.scss';

ReactDOM.render(
  <Auth0Provider
    domain="dev-7minb0dt.us.auth0.com"
    clientId="ICoZK5wfKvi9gGWsSD2zEzixigrczxCZ"
    redirectUri={`${window.location.origin}/steps`}
  >
    <React.StrictMode>
      <ParallaxProvider>
        <Routes />
      </ParallaxProvider>
    </React.StrictMode>
  </Auth0Provider>,
  document.getElementById('root')
);

 