/**
 * Internal dependencies
 */
import React, { useState } from 'react';

/**
 * External dependencies
 */
import { Flex, FlexCol } from 'components/flex/flex';

const SignRow = () => {
  const [value, setValue] = useState('');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value);

  return (
    <div className="sign-row">
      <div className="container">
        <Flex className="between align-center mobile-block">
          <FlexCol>
            <h3>Interested in becoming part of our<br/> community? Sign up to be in the know</h3>
          </FlexCol>
          <FlexCol>
            <form action="?">
              <Flex className="between">
                <FlexCol>
                  <input type="email" value={value} name="email" placeholder="Email" onChange={handleChange} />
                </FlexCol>
                <FlexCol>
                  <button type="submit">SEND</button>
                </FlexCol>
              </Flex>
            </form>
          </FlexCol>
        </Flex>
      </div>
    </div>
  )
}

export default SignRow
