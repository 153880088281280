import React from 'react';
import {
  NavLink
} from "react-router-dom";

/**
 * Internal dependencies
 */
import { Flex, FlexCol } from 'components/flex/flex';

import LogoMail from 'assets/images/svg/ico-foot-mail.svg';
import LogoTwitter from 'assets/images/svg/ico-foot-twitter.svg';
import LogoFacebook from 'assets/images/svg/ico-foot-facebook.svg';
import LogoInstagram from 'assets/images/svg/ico-foot-instagram.svg';
import Logo from 'components/logo/logo';
import AuthButton from 'components/auth-button/auth-button';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <Flex className="between align-center mobile-block">
          <FlexCol>
            <NavLink to="/" className="footer-logo">
              <Logo color="#fff" />
            </NavLink>
            <span className="footer-text">© Klothed Technologies Inc</span>
          </FlexCol>
          <FlexCol>
            <Flex className="align-center mobile-block">
              <FlexCol className="social-links">
                <a href="mailto:hello@getklothed.com" target="_blank" rel="noreferrer"><img src={LogoMail} alt="e-mail" /></a>
                <a href="https://twitter.com/getklothed_" target="_blank" rel="noreferrer"><img src={LogoTwitter} alt="twitter" /></a>
                <a href="https://www.facebook.com/getklothedapp" target="_blank" rel="noreferrer"><img src={LogoFacebook} alt="facebook" /></a>
                <a href="https://www.instagram.com/get.klothed" target="_blank" rel="noreferrer"><img src={LogoInstagram} alt="instagram" /></a>
              </FlexCol>
              <FlexCol>
                <AuthButton />
              </FlexCol>
            </Flex>
          </FlexCol>
        </Flex>
      </div>
    </footer>
  )
}

export default Footer
