/**
 * External dependencies
 */
import React, {useEffect} from 'react';
import { useAuth0 } from "@auth0/auth0-react";

/**
 * Internal dependencies
 */


const SignUp = () => {
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    loginWithRedirect();
  });
  return (
    <></>
  )
}

export default SignUp
