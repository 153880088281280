import React from 'react';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { Flex, FlexCol } from 'components/flex/flex'
import StepHolder from 'components/step-holder/step-holder';
import DragDrop from 'components/drag-drop/drag-drop';

const schema = yup.object().shape({
  file: yup
    .string()
    .required(),
});

type FormData = {
  file: string,
};


const Defaults = () => {
  const { control, handleSubmit } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: "onChange"
  });
  const onSubmit = (data: FormData) => console.log('submitted');

  return (
    <div className="steps bg-dark">
      <div className="main-wrapper">
        <div className="container">
        <Flex className="between mobile-block">
            <FlexCol className="steps-left">
              <h3>A few tips for success</h3>
            </FlexCol>
            <FlexCol className="steps-right">
              
            </FlexCol>
          </Flex>
          <Flex className="between mobile-block">
            <FlexCol className="steps-left flex-end">
              <h1>Everything looks<br/> better on <em>you</em>.</h1>
              <p>Try on styles from your favorite online retailer</p>
            </FlexCol>
            <FlexCol className="steps-right">
              <form onSubmit={handleSubmit(onSubmit)} className="step-form">
                <StepHolder title="UPLOAD A PHOTO" subtitle="Create your profile"  className='is-visible'>
                  <Controller
                    control={control}
                    render={({ field: { onChange } }) => (
                      <DragDrop onChange={onChange} value={''} error={true} />
                    )}
                    name="file"
                    defaultValue=""
                  />
                </StepHolder>
              </form>
            </FlexCol>
          </Flex>
          
        </div>
      </div>
    </div>
  )
}

export default Defaults
