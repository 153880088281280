import React from 'react'

type Prop = {
  color: string
}

const Logo = ({ color }: Prop) => {
  return (
    <svg width="206" height="38" viewBox="0 0 206 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M67.8299 10.3682C60.3446 10.3682 54.2852 16.4885 54.2852 24.049C54.2852 31.6094 60.3446 37.7297 67.8299 37.7297C75.3151 37.7297 81.3746 31.6094 81.3746 24.049C81.3746 16.4885 75.3151 10.3682 67.8299 10.3682ZM67.8299 34.4535C62.1268 34.4535 57.5288 29.8093 57.5288 24.049C57.5288 18.2886 62.1268 13.6444 67.8299 13.6444C73.5329 13.6444 78.131 18.2886 78.131 24.049C78.131 29.8093 73.5329 34.4535 67.8299 34.4535Z" fill={color}/>
      <path d="M51.0773 34.2381V37.2622H30.083V10.8369H33.5405V34.2381H51.0773Z" fill={color}/>
      <path d="M110.141 13.8611H99.6257V37.2622H96.1683V13.8611H85.6533V10.8369H110.141V13.8611Z" fill={color}/>
      <path d="M141.364 10.8369V37.2983H137.907V25.4177H120.334V37.2983H116.877V10.8369H120.334V22.3935H137.907V10.8369H141.364Z" fill={color}/>
      <path d="M153.162 13.8611V22.3935H170.199V25.4176H153.162V34.2381H172.552V37.2622H149.704V10.8369H172.552V13.8611H153.162Z" fill={color}/>
      <path d="M153.055 13.7522V22.2847H170.093V25.3089H153.055V34.1294H172.446V37.1536H149.598V10.728H172.446V13.7522H153.055Z" fill={color}/>
      <path d="M192.227 10.8369H180.679V37.2983H192.227C199.463 37.2983 205.309 31.394 205.309 24.0856C205.309 16.7772 199.463 10.8369 192.227 10.8369ZM191.942 34.2382H184.101V13.8611H191.942C197.503 13.8611 202.029 18.4333 202.029 24.0496C202.029 29.6659 197.538 34.2382 191.942 34.2382Z" fill={color}/>
      <path d="M67.8305 0L62.0205 6.62427H65.3354L67.8305 3.78015L70.3256 6.62427H73.6404L67.8305 0Z" fill={color}/>
      <path d="M23.6676 37.2982L13.5447 22.3574L21.3864 10.8368H17.3586L3.45746 30.8539V10.8368H0V37.2982H3.31489L11.2635 25.7056L11.4417 25.4536L19.6398 37.2982H23.6676Z" fill={color}/>
    </svg>
  )
}

export default Logo
