/**
 * External dependencies
 */
import React from 'react'

/**
 * Internal dependencies
 */
import introImage from 'assets/images/intro-image.jpg';
import AuthButton from 'components/auth-button/auth-button';

const Intro = () => {
  return (
    <div className="intro">
      <div className="intro__col intro__col--text">
        <h2>Everything looks<br/> better on you.</h2>
        <p>Try on styles from your favorite online retailer</p>
        <AuthButton />
      </div>
      <div className="intro__col intro__col--image" style={{backgroundImage: `url(${introImage})`}}></div>
    </div>
  )
}

export default Intro
