/**
 * External dependencies
 */
import React from 'react';
import { Parallax } from 'react-scroll-parallax';

/**
 * Internal dependencies
 */
import Intro from 'components/intro/intro';
import TextImage from 'components/text-image/text-image';
import { Flex, FlexCol } from 'components/flex/flex';
import SignRow from 'components/sign-row/sign-row';

import img1 from 'assets/images/img1.jpg';
import img2 from 'assets/images/img2.jpg';
import img3 from 'assets/images/img3.jpg';
import KImage from 'assets/images/k-image.png';

const Home = () => {
  return (
    <div>
      <Intro />
      <TextImage style={{background: '#000', color: '#fff'}}>
        <Flex className="align-center between mobile-block">
          <FlexCol half>
            <Flex className="align-center center">
              <FlexCol>
                <Parallax className="custom-class" y={[10, -10]} tagOuter="figure">
                  <img src={img1} width="203" height="363" alt="" className="m0-4-8" />
                </Parallax>
              </FlexCol>
              <FlexCol>
                <Parallax className="custom-class" y={[-10, 10]} tagOuter="figure">
                  <img src={img2} width="161" height="212" alt="" className="m0-4-8" />
                  <img src={img3} width="235" height="240" alt="" className="m0-4-8" />
                </Parallax>
              </FlexCol>
            </Flex>
          </FlexCol>
          <FlexCol half>
            <div className="text-image__content">
              <h2>CREATE<br/> ENVISION<br/> DISCOVER</h2>
              <p>At Klothed, we recognize that everyone is uniquely beautiful. We believe you should be able to discover and develop your distinct style online – not just when you shop in person. Our technology enables you to see how any style, at any online retailer, looks on you before you buy so you can make fewer returns and enjoy the rush of every single purchase.</p>
            </div>
          </FlexCol>
        </Flex>
      </TextImage>
      <TextImage>
        <Flex className="mobile-block">
          <FlexCol half>
            <div className="text-image__content m0-a">
              <h4>TEAM</h4>
              <p className="large">Founded in New York, we’ve brought together leaders in fashion, retail technology, AI + ML, and computer vision, to craft a social shopping experience for consumers that reduces returns and rivals in-store shopping.</p>
              <p className="large">The Klothed team has decades of collective experience building and guiding startups startups through successful exits, developing new-to-the-world products, creating categories, and designing and growing brands. Our diverse backgrounds unite to revolutionize your online shopping experience.</p>
            </div>
          </FlexCol>
          <FlexCol half>
            <Parallax className="custom-class" x={[20,0]} tagOuter="figure">
              <img src={KImage} width="436" height="466" alt="Klothed" />
            </Parallax>
          </FlexCol>
        </Flex>
      </TextImage>
      <SignRow />
    </div>
  )
}

export default Home;
